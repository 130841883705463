import * as React from "react";
//@ts-ignore
import CardsImg from "../images/cards.png";
import styled from "styled-components";
import Container from "./common/Container";
import { Spacer } from "./common";

const Cards = styled.section`
  padding-top: 120px;
  padding-bottom: 120px;
`;

const CardsImage = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  img {
    max-width: 90%;
  }
`;

const CardsContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
`;

const FlexDiv = styled.div`
  display: flex;
  @media only screen and (max-width: 1200px) {
    flex-direction: column-reverse;
  }
`;

const CardsSection = () => {
  return (
    <Cards id="aplikacija">
      <Container>
        <FlexDiv>
          <CardsImage>
            <img src={CardsImg} />
          </CardsImage>
          <CardsContent>
            <h1 className="text-darker">Dodaj karticu. Ostvari benefite. </h1>
            <Spacer />
            <p className="text-dark">
              Skeniraj plastičnu karticu i izradi digitalnu kopiju. Bilo da si u
              supermarketu, benziskoj stanici ili butiku, na kasi pokaži
              aplikaciju i uvjek ostvari <strong>benefite.</strong>
            </p>
          </CardsContent>
        </FlexDiv>
      </Container>
    </Cards>
  );
};

export default CardsSection;
