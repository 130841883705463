import * as React from "react";
import styled from "styled-components";
//@ts-ignore
import ShoppingImgUrl from "../images/phone-features.png";
import Container from "./common/Container";
import { ListIcon } from "./Features";

const ShoppingSectionStyled = styled.section`
  padding-bottom: 260px;
  h1 {
    max-width: 800px;
    text-align: center;
    margin: 0 auto;
    font-size: 42px;
  }
  @media only screen and (max-width: 1240px) {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 130px;
  }
  @media only screen and (max-width: 860px) {
    padding-left: 0px;
    padding-right: 0px;
    h1 {
      padding-left: 24px;
      padding-right: 94px;
      text-align: left;
      position: relative;
      bottom: -42px;
    }
  }
`;
const IconsList = styled.div`
  display: flex;
  justify-content: center;
  margin: 32px 0;
  @media only screen and (max-width: 860px) {
    display: none;
  }
`;

const ShoppingSpacerStyled = styled.div<{ right?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  .circle {
    width: 13px;
    height: 13px;
    background-color: var(--primary);
    border-radius: 100%;
  }
  .shopping-line {
    height: 1px;
    background-color: #e4e4e4;
    width: 100%;
  }
  ${({ right = false }) =>
    right &&
    `
      width: 100%;
      flex-direction: row-reverse;
    `}
`;

const ShoppingContainer = styled.div`
  display: flex;
  @media only screen and (max-width: 860px) {
    flex-direction: column;
  }
`;

const ShoppingItemContainer = styled.div`
  flex: 1;
  display: flex;
`;
const SpacerSmall = styled.div`
  width: 69px;
  height: 6px;
  border-radius: 3px;
  background-color: var(--primary);
  @media only screen and (max-width: 860px) {
    display: none;
  }
`;
const ShoppingItemDisplay = styled.div<{ right?: boolean }>`
  @media only screen and (min-width: 860px) {
    display: none;
  }
  width: 73px;
  background: linear-gradient(170.57deg, #8216d5 19.58%, #5b0ab0 93.77%);
  border-top-left-radius: ${(props) => (props.right ? "30px" : "0px")};
  border-bottom-left-radius: ${(props) => (props.right ? "30px" : "0px")};
  border-top-right-radius: ${(props) => (props.right ? "0px" : "30px")};
  border-bottom-right-radius: ${(props) => (props.right ? "0px" : "30px")};
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    writing-mode: tb-rl;
    transform: rotate(-180deg);
    font-size: 28px;
    color: var(--text-light);
    font-weight: 600;
  }
`;
const ShoppingItem = styled.div<{ right?: boolean }>`
  flex: 1;
  @media only screen and (max-width: 860px) {
    padding: ${(props) =>
      props.right ? "24px 24px 48px 0px" : "80px 0px 22px 24px"};
  }
  h3 {
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    color: var(--text-darker);
    margin-top: 22px;
    margin-bottom: 0;
  }

  h4 {
    font-weight: 700;
    font-size: 18px;
    color: var(--text-darker);
    margin: 22px 0 32px 0;
  }

  p {
    color: var(--text-dark);
    font-size: 16px;
    margin: 0;
    max-width: 85%;
  }
  ${({ right = false }) =>
    right &&
    `
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    `}
`;

const ShoppingImg = styled(ShoppingItem)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 58px;
  padding-left: 0px;
  @media only screen and (max-width: 860px) {
    img {
      max-width: 80%;
    }
  }
`;
const ShoppingFeature = styled.div`
  margin-top: 92px;
  @media only screen and (max-width: 860px) {
    margin-top: 32px;
  }
`;

const ShoppingFeatureLeft = styled(ShoppingFeature)`
  padding-right: 32px;
`;

const ShoppingFeatureRight = styled(ShoppingFeature)`
  padding-left: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  p {
    text-align: right;
  }
`;

const ShoppingSpacer = ({ right = false }: { right?: boolean }) => (
  <ShoppingSpacerStyled right={right}>
    <div className="circle" />
    <div className="shopping-line" />
  </ShoppingSpacerStyled>
);

const ShoppingSection = () => {
  return (
    <ShoppingSectionStyled>
      <Container>
        <h1>Kompletno šoping iskustvo</h1>
        <IconsList>
          <ListIcon small>
            <div className="top"></div>
            <div className="middle bg-purple"></div>
          </ListIcon>
          <ListIcon small>
            <div className="top"></div>
            <div className="middle bg-orange"></div>
          </ListIcon>
          <ListIcon small>
            <div className="top"></div>
            <div className="middle bg-yellow"></div>
          </ListIcon>
        </IconsList>
        <ShoppingContainer>
          <ShoppingItemContainer>
            <ShoppingItem id="soping">
              <SpacerSmall />
              <h3>Za tebe! </h3>
              <ShoppingFeatureLeft>
                <ShoppingSpacer />
                <h4>Novčanik</h4>
                <p>
                  Digitalizuj sve plastične kartice i prijavi se za nove
                  programe.
                </p>
              </ShoppingFeatureLeft>
              <ShoppingFeatureLeft>
                <ShoppingSpacer />
                <h4>Benefiti</h4>
                <p>Pokaži karticu na kasi i ostvari sve benefite.</p>
              </ShoppingFeatureLeft>
              <ShoppingFeatureLeft>
                <ShoppingSpacer />
                <h4>Istraži </h4>
                <p>
                  Pronađi popuste samo za tebe i iskoristi ih na pravi način.
                </p>
              </ShoppingFeatureLeft>
            </ShoppingItem>
            <ShoppingItemDisplay right>
              <p>Za tebe</p>
            </ShoppingItemDisplay>
          </ShoppingItemContainer>
          <ShoppingImg>
            <img src={ShoppingImgUrl} />
          </ShoppingImg>
          <ShoppingItemContainer>
            <ShoppingItemDisplay>
              <p>Za prodavce</p>
            </ShoppingItemDisplay>
            <ShoppingItem right id="za-prodavce">
              <div className="spacer-small" />
              <h3>Za prodavce! </h3>
              <ShoppingFeatureRight>
                <ShoppingSpacer right />
                <h4>Akvizicija</h4>
                <p>Izdajte digitalne loyalty kartice novim potrošačima.</p>
              </ShoppingFeatureRight>
              <ShoppingFeatureRight>
                <ShoppingSpacer right />

                <h4>Interakcija</h4>
                <p>
                  Komunicirajte sa vašim kupcima prije, tokom i nakon trgovine!{" "}
                </p>
              </ShoppingFeatureRight>
              <ShoppingFeatureRight>
                <ShoppingSpacer right />

                <h4>Analitika </h4>
                <p>
                  Uz našu API integraciju pružite uvid potrošačima u ostvarene
                  benefite.
                </p>
              </ShoppingFeatureRight>
            </ShoppingItem>
          </ShoppingItemContainer>
        </ShoppingContainer>
      </Container>
    </ShoppingSectionStyled>
  );
};

export default ShoppingSection;
