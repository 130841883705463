import * as React from "react";
import Container from "./common/Container";
import LogoFull from "./LogoFull";
import styled from "styled-components";
import { MenuIcon } from "../icons";
import { PopoverDemo } from "./Dropdown";

const Download = styled.div`
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

const DownloadLink = styled.a`
  font-weight: 500;
  color: var(--text-light);
  text-decoration: none;
  font-size: 14px;
  border: 1px solid var(--text-light);
  border-radius: 8px;
  padding: 10px 36px;
  &:hover {
    background-color: #ac72d9;
  }
`;

const Nav = styled.nav`
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 1200px) {
    padding: 50px 20px 0px 20px;
  }
  .logo {
    display: flex;
    align-items: center;
  }
`;

export const Link = styled.a`
  padding: 0 16px;
  font-weight: 600;
  color: var(--text-light);
  text-decoration: none;
  font-size: 14px;
`;

const NavLinks = styled.div`
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

const MobileMenu = styled.div`
  display: none;
  @media only screen and (max-width: 1200px) {
    display: flex;
  }
`;

const MobileMenuContainer = styled.div`
  background-color: #fff;
`;

const StyledHeader = styled.header``;
const Header = () => {
  return (
    <StyledHeader>
      <Container>
        <Nav>
          <div className="logo">
            <LogoFull />
          </div>
          <NavLinks>
            <Link href="#funkcionalnosti">O nama</Link>
            <Link href="#soping">Za kupce</Link>
            <Link href="#za-prodavce">Za prodavce</Link>
            <Link href="#kontakt">Kontakt</Link>
          </NavLinks>
          <MobileMenu>
            <PopoverDemo />
            {/* <MenuIcon /> */}
          </MobileMenu>
          <Download>
            <DownloadLink href="#preuzmi">Preuzmi</DownloadLink>
          </Download>
        </Nav>
        {/* <MobileMenuContainer>
          <h1>test</h1>
        </MobileMenuContainer> */}
      </Container>
    </StyledHeader>
  );
};

export default Header;
