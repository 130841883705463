import * as React from "react";

export const FacebookIcon = (
  { color }: { color?: string } = { color: "#fff" }
) => (
  <svg width={33} height={33} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.748 28.5V17.553h3.744l.562-4.267h-4.306v-2.724c0-1.235.348-2.077 2.156-2.077l2.302-.001V4.667c-.398-.05-1.765-.167-3.355-.167-3.321 0-5.595 1.988-5.595 5.64v3.146H10.5v4.267h3.756V28.5h4.492Z"
      fill={color}
    />
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={10}
      y={4}
      width={14}
      height={25}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.748 28.5V17.553h3.744l.562-4.267h-4.306v-2.724c0-1.235.348-2.077 2.156-2.077l2.302-.001V4.667c-.398-.05-1.765-.167-3.355-.167-3.321 0-5.595 1.988-5.595 5.64v3.146H10.5v4.267h3.756V28.5h4.492Z"
        fill={color}
      />
    </mask>
  </svg>
);

export const InstagramIcon = (
  { color }: { color?: string } = { color: "#fff" }
) => (
  <svg width={33} height={33} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.702 9.878a6.91 6.91 0 0 0-6.92 6.922 6.91 6.91 0 0 0 6.92 6.922 6.91 6.91 0 0 0 6.921-6.922 6.91 6.91 0 0 0-6.92-6.922Zm0 11.423a4.508 4.508 0 0 1-4.5-4.501c0-2.482 2.019-4.5 4.5-4.5 2.482 0 4.5 2.018 4.5 4.5s-2.024 4.5-4.5 4.5ZM24.52 9.594a1.61 1.61 0 0 1-1.614 1.615 1.615 1.615 0 1 1 1.614-1.615Zm4.584 1.64c-.102-2.164-.596-4.08-2.18-5.658-1.578-1.579-3.494-2.073-5.656-2.181-2.229-.127-8.909-.127-11.137 0-2.157.102-4.072.596-5.656 2.175-1.585 1.578-2.072 3.494-2.18 5.657-.127 2.23-.127 8.91 0 11.14.101 2.163.595 4.079 2.18 5.657 1.584 1.579 3.493 2.073 5.656 2.181 2.228.127 8.908.127 11.137 0 2.162-.102 4.078-.596 5.656-2.18 1.578-1.58 2.072-3.495 2.18-5.658.127-2.23.127-8.905 0-11.134Zm-2.879 13.525a4.556 4.556 0 0 1-2.566 2.566c-1.777.705-5.993.543-7.957.543-1.963 0-6.186.156-7.957-.543A4.556 4.556 0 0 1 5.18 24.76c-.704-1.777-.542-5.995-.542-7.959 0-1.964-.156-6.187.542-7.959a4.556 4.556 0 0 1 2.566-2.566c1.777-.705 5.994-.542 7.957-.542 1.964 0 6.186-.157 7.957.542 1.18.47 2.09 1.38 2.566 2.566.705 1.778.542 5.995.542 7.959 0 1.964.163 6.188-.542 7.959Z"
      fill={color}
    />
  </svg>
);

export const TwitterIcon = (
  { color }: { color?: string } = { color: "#fff" }
) => (
  <svg width={30} height={33} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.273 8.45a8.833 8.833 0 0 1-2.731.825c.982-.647 1.734-1.674 2.09-2.893a9.078 9.078 0 0 1-3.022 1.27C22.743 6.634 21.51 6 20.14 6c-2.627 0-4.756 2.342-4.756 5.23 0 .41.042.81.123 1.192-3.952-.219-7.456-2.301-9.801-5.466a5.594 5.594 0 0 0-.643 2.63c0 1.814.84 3.416 2.115 4.352a4.432 4.432 0 0 1-2.154-.656v.065c0 2.534 1.64 4.649 3.814 5.13a4.408 4.408 0 0 1-1.253.183c-.307 0-.604-.034-.895-.097.605 2.08 2.36 3.591 4.441 3.633-1.627 1.402-3.678 2.235-5.906 2.235a9.2 9.2 0 0 1-1.134-.071c2.105 1.486 4.603 2.353 7.29 2.353 8.747 0 13.529-7.97 13.529-14.883l-.016-.677a10.071 10.071 0 0 0 2.379-2.702Z"
      fill={color}
    />
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={4}
      y={6}
      width={24}
      height={21}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.273 8.45a8.833 8.833 0 0 1-2.731.825c.982-.647 1.734-1.674 2.09-2.893a9.078 9.078 0 0 1-3.022 1.27C22.743 6.634 21.51 6 20.14 6c-2.627 0-4.756 2.342-4.756 5.23 0 .41.042.81.123 1.192-3.952-.219-7.456-2.301-9.801-5.466a5.594 5.594 0 0 0-.643 2.63c0 1.814.84 3.416 2.115 4.352a4.432 4.432 0 0 1-2.154-.656v.065c0 2.534 1.64 4.649 3.814 5.13a4.408 4.408 0 0 1-1.253.183c-.307 0-.604-.034-.895-.097.605 2.08 2.36 3.591 4.441 3.633-1.627 1.402-3.678 2.235-5.906 2.235a9.2 9.2 0 0 1-1.134-.071c2.105 1.486 4.603 2.353 7.29 2.353 8.747 0 13.529-7.97 13.529-14.883l-.016-.677a10.071 10.071 0 0 0 2.379-2.702Z"
        fill={color}
      />
    </mask>
  </svg>
);

export const LinkedInIcon = ({ color } = { color: "#fff" }) => (
  <svg width={33} height={33} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.4 4.125H6.6A2.482 2.482 0 0 0 4.125 6.6v19.8A2.482 2.482 0 0 0 6.6 28.875h19.8a2.482 2.482 0 0 0 2.475-2.475V6.6A2.482 2.482 0 0 0 26.4 4.125ZM11.55 25.163H7.837V14.025h3.713v11.138ZM9.694 11.92a2.218 2.218 0 0 1-2.228-2.227c0-1.238.99-2.228 2.228-2.228 1.237 0 2.227.99 2.227 2.228 0 1.237-.99 2.227-2.227 2.227Zm15.469 13.242H21.45v-6.56c0-.99-.866-1.856-1.856-1.856-.99 0-1.857.867-1.857 1.857v6.559h-3.712V14.025h3.713v1.485c.618-.99 1.98-1.732 3.093-1.732 2.352 0 4.332 1.98 4.332 4.33v7.055Z"
      fill={color}
    />
  </svg>
);

export const DownloadIcon = (props: any) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.395 19.462a.856.856 0 0 0 1.211.001l7.714-7.714a.856.856 0 1 0-1.212-1.212l-6.25 6.252V.857a.858.858 0 0 0-1.715 0V16.79l-6.251-6.252A.857.857 0 1 0 3.68 11.75l7.715 7.713Zm10.034 2.824H2.572a.858.858 0 0 0 0 1.714h18.857a.857.857 0 1 0 0-1.714Z"
      fill="#fff"
    />
  </svg>
);

export const MenuIcon = (props: any) => (
  <svg
    width={21}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 4.5A1.5 1.5 0 0 1 1.5 3h18a1.5 1.5 0 1 1 0 3h-18A1.5 1.5 0 0 1 0 4.5ZM0 12c0-.83.672-1.5 1.5-1.5h18c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-18A1.5 1.5 0 0 1 0 12Zm19.5 9h-18a1.5 1.5 0 1 1 0-3h18c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5Z"
      fill="#fff"
    />
  </svg>
);
