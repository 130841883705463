import React from "react";
import styled from "styled-components";

const Container = styled.div<{ full?: boolean }>`
  max-width: 1142px;
  margin: 0 auto;
  ${({ full = false }) =>
    full &&
    `
        flex: 1;
        display: flex;
    `}
`;

export default Container;
