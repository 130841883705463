import * as React from "react";
import styled from "styled-components";
import { AppStoreIcon, PlayStoreIcon } from "../icons/responsive";
//@ts-ignore
import LogoImg from "../images/bg-logo.png";
import AppStore from "./AppStore";
import Container from "./common/Container";
import { DownloadButtonsContainer, StoreDownloadButton } from "./Features";
import PlayStore from "./PlayStore";

const DownloadSectionStyled = styled.section`
  padding: 46px 0;
  @media only screen and (max-width: 1240px) {
    padding: 46px 24px;
  }
`;

const DownloadContainer = styled.div`
  display: flex;
  @media only screen and (max-width: 860px) {
    flex-direction: column;
  }
  .item {
    display: flex;
    flex: 1;
    h2 {
      font-weight: 700;
      font-size: 42px;
      line-height: 52px;
      color: var(--text-light);
      margin: 0 0 38px 0;
    }
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: var(--text-light);
      margin-bottom: 14px;
      margin-top: 0;
    }
    img {
      width: 220px;
    }
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .right {
    display: flex;
    justify-content: flex-end;
  }
  @media only screen and (max-width: 860px) {
    .right {
      display: none;
    }
  }
`;
const DownloadSection = () => {
  return (
    <DownloadSectionStyled className="bg-primary" id="preuzmi">
      <Container>
        <DownloadContainer>
          <div className="item left">
            <h2>Preuzmite besplatnu mobilnu aplikaciju</h2>
            <p
              style={{
                marginBottom: "24px",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              Iskoristite sve pogodnosti uz sledeće korake:
            </p>
            <p>1. Preuzmi aplikaciju</p>
            <p>2. Skeniraj postojeću karticu ili se registruj za novu</p>
            <p>3. Kupuj sa lakoćom </p>
            <div
              style={{
                marginTop: "18px",
              }}
            >
              <DownloadButtonsContainer>
                <StoreDownloadButton
                  href="https://play.google.com/store/apps/details?id=me.konfidi.wallet"
                  target="_blank"
                >
                  <PlayStoreIcon />
                </StoreDownloadButton>
                <StoreDownloadButton
                  href="https://apps.apple.com/us/app/konfidi-kartice-na-jedan-klik/id1633509284"
                  target="_blank"
                >
                  <AppStoreIcon />
                </StoreDownloadButton>
              </DownloadButtonsContainer>
            </div>
          </div>
          <div className="item right">
            <img src={LogoImg} alt="" />
          </div>
        </DownloadContainer>
      </Container>
    </DownloadSectionStyled>
  );
};

export default DownloadSection;
