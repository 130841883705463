import styled from "styled-components";

export const Spacer = styled.div`
  width: 140px;
  height: 6px;
  border-radius: 3px;
  background-color: var(--primary);
  margin-top: 64px;
  margin-bottom: 12px;

  @media only screen and (max-width: 600px) {
    width: 101px;
    height: 4px;
    margin-top: 24px;
    margin-bottom: 10px;
  }
`;
