import * as React from "react";

import styled from "styled-components";
//@ts-ignore
import peopleImage from "../images/people.png";

const PeopleSectionStyled = styled.section`
  background-image: url(${peopleImage});
  height: 540px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
`;

const PeopleSection = () => {
  return <PeopleSectionStyled />;
};

export default PeopleSection;
