import * as React from "react";
import styled from "styled-components";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  TwitterIcon,
} from "../icons";
import Container from "./common/Container";

const ContactSectionStyled = styled.section`
  background-color: #efefef;
  padding: 72px 0;

  h2 {
    font-weight: 700;
    font-size: 42px;
    line-height: 52px;
    color: var(--text-darker);
    margin: 0;
  }
  @media only screen and (max-width: 1200px) {
    padding: 72px 24px;
  }
  .contact-flex {
    display: flex;
  }

  @media only screen and (max-width: 860px) {
    .contact-flex {
      flex-direction: column;
    }
  }
`;

const ContactLeft = styled.div`
  flex: 1;
`;
const ContactRight = styled.div`
  flex: 1;
  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 48px;
    input {
      background-color: var(--text-light);
      padding: 16px;
      font-size: 16px;
      width: 80%;
      border-radius: 10px;
      margin-bottom: 24px;
      border: 1px solid var(--text-light);
      outline: 1px solid var(--text-light);
      font-family: "Poppins", sans-serif;
    }

    textarea {
      background-color: var(--text-light);
      padding: 16px;
      font-size: 16px;
      width: 80%;
      border-radius: 10px;
      border: 1px solid var(--text-light);
      margin-bottom: 24px;
      font-family: "Poppins", sans-serif;
    }
    input:focus {
      border: 1px solid var(--primary);
      outline: 1px solid var(--primary);
    }
    input:focus-visible {
      border: 1px solid var(--primary);
      outline: 1px solid var(--primary);
    }

    textarea:focus {
      border: 1px solid var(--primary);
      outline: 1px solid var(--primary);
    }

    textarea:focus-visible {
      border: 1px solid var(--primary);
      outline: 1px solid var(--primary);
    }
  }

  @media only screen and (max-width: 860px) {
    form {
      padding-left: 0px;
      input {
        margin-top: 24px;
        width: calc(100% - 32px);
      }
      textarea {
        width: calc(100% - 32px);
      }
    }
  }
`;

const ContactButton = styled.button`
  border-radius: 8px;
  background-color: var(--text-darker);
  color: var(--text-light);
  width: 161px;
  font-weight: 700;
  font-size: 16px;
  border: unset;
  width: 161px;
  height: 46px;
  cursor: pointer;
  &:hover {
    background-color: #5c5c5c;
  }
`;

const Loader = () => (
  <div className="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SocialLink = styled.a`
  /* padding: 4px; */
  width: 41px;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ContactSection = () => {
  const form = React.useRef<null | HTMLFormElement>(null);
  const email = React.useRef<null | HTMLInputElement>(null);
  const message = React.useRef<null | HTMLTextAreaElement>(null);

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  async function onFormSubmit(e: React.SyntheticEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);
    if (!form?.current) {
      return;
    }
    const formData = new FormData(form.current);

    const response = await fetch("/api/email", {
      method: "POST",
      body: JSON.stringify({
        email: email.current?.value,
        message: message.current?.value,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    setLoading(false);
    setSuccess(true);
    const data = await response.json();
    console.log("data", data);
  }

  return (
    <ContactSectionStyled id="kontakt">
      <Container>
        <div className="contact-flex">
          <ContactLeft>
            <h2>Postavi nam pitanje ili pošalji utiske! </h2>
            <div>
              <div className="contact-spacer" />
            </div>

            <div className="icons">
              <SocialLink
                href="https://www.facebook.com/konfidi1"
                target="_blank"
              >
                <FacebookIcon color="#343434" />
              </SocialLink>
              <SocialLink
                href="https://www.instagram.com/konfidi.me/"
                target="_blank"
              >
                <InstagramIcon color="#343434" />
              </SocialLink>
              <SocialLink
                href="https://www.linkedin.com/company/konfidi1"
                target="_blank"
              >
                <LinkedInIcon color="#343434" />
              </SocialLink>
            </div>
          </ContactLeft>
          <ContactRight>
            <form onSubmit={onFormSubmit} ref={form}>
              <input
                type="email"
                placeholder="Email adresa"
                required
                name="email"
                ref={email}
              />
              <textarea
                ref={message}
                required
                name="message"
                id=""
                cols={30}
                rows={8}
                placeholder="Poruka"
              ></textarea>
              <ButtonContainer>
                {success ? (
                  <p>
                    Hvala što ste nas kontaktirali, ubrzo ćete dobiti odgovor.
                  </p>
                ) : (
                  <ContactButton type="submit" disabled={loading}>
                    Pošalji
                  </ContactButton>
                )}

                {loading && <Loader />}
              </ButtonContainer>
            </form>
          </ContactRight>
        </div>
      </Container>
    </ContactSectionStyled>
  );
};

export default ContactSection;
